@font-face {
  font-family: "Lato Regular";
  src: url('../public/fonts/lato/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Lato Bold";
  src: url('../public/fonts/lato/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Lato Italic";
  src: url('../public/fonts/lato/Lato-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Lato Light";
  src: url('../public/fonts/lato/Lato-Light.ttf') format('truetype');
}
@font-face {
  font-family: "Lato Thin";
  src: url('../public/fonts/lato/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: "Lato Black";
  src: url('../public/fonts/lato/Lato-Black.ttf') format('truetype');
}

html, body {
  font-family: 'Lato Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 15px;
}

.theme-dark,
.theme-light {
  height: auto;
  min-height: 100vh;
}

html,
body,
#root{
  height: 100%;
  min-height: 100vh;
}
h1, h2, h3 {
  font-family: 'Lato Black';
}
h4, h5, h6 {
  font-family: 'Lato Bold';
}

h2.sg-h2 {
  font-size: 40px;
  margin-bottom: 45px;
}

h3.sg-h3 {
  font-size: 32px;
}

h4.sg-h4 {
  font-size: 13px;
}

.theme-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.hidden-top-ads.calls-page {
  /* The Ad Slider neeeds additional space */
  padding-top: 98px;
}
/* .marq-hidden-top-ads {
  margin-top: 185px;
} */

/* .calls-page {
  padding-top: 98px;
} */

.calls-page .header-outer .app-header{
  top: 0;
  position: relative;
  padding: 10px 20px;
}
.calls-page .header-outer .app-header.hidden-top-ads {
  top: 0;
  position: fixed;
}
.top-ads {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 9;
  background-color: #181821;
}

.top-ads > div > a {
  display: flex;
}

.top-ads img {
  width: 100%;
  max-height: 98px;
}

.theme-dark {
  overflow: hidden;
}

.theme-light {
  background-color: #fff;
  color: #1e2329;
  overflow: hidden;
}

.theme-light * {
  color: #1e2329;
}

.theme-light .btn-primary svg,
.theme-light .btn-primary path {
  fill: #fff !important;
  color: #fff !important;
}

.custom-cell .btn-primary {
  padding: 7px 23px;
  width: auto !important;
  font-size: 13px;
  font-family: 'Lato Bold';
  margin: 0;
}

.top-grids .custom-cell .btn-primary {
  padding: 4px 23px;
}

.main-out {
  display: flex;
  background: url('../public/img/backgrounds/main-bg.jpg') no-repeat;
  background-size: 100%;
  padding-bottom: 350px;
  background-position: bottom;
}
.no-background .main-out {
  background: transparent;
}
.main-l, 
.main-r {
  /* width: calc((100% - 1278px) / 2); */
  padding-top: 140px;
}
.main-l img, 
.main-r img {
  max-width: 100%;
}

.main-l > div,
.main-r > div {
  width: 100%;
  height: 100%;
}

.main-l > div {
  width: 100%;
}

.main-r > div {
  background-size: cover;
}

.h-70 {
  height: 70px;
}
.gap-70 {
  gap: 70px;
}
.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.m-bottom-100 {
  margin-bottom: 100px;
}

/* .main-l-t {
  background: url('../public/img/backgrounds/bg-t-l.jpg') no-repeat;
  max-height: 1700px;
}
.main-r-t {
  background: url('../public/img/backgrounds/bg-t-r.jpg') no-repeat;
  max-height: 1700px;
}
.main-l-m {
  background: url('../public/img/backgrounds/bg-m-l.jpg') no-repeat;
  max-height: 1500px;
  position: relative;
  z-index: 1;
}
.main-r-m {
  background: url('../public/img/Right_mid_960x1500.png') no-repeat;
  max-height: 1500px;
} */

/* .main-r {
  display: flex;
} */

.right {
  margin-left: auto;
}

.img-responsive {
  max-width: 100%;
}

.theme-dark,
.theme-dark .main-content {
  background-color: #181821;
  background-color: transparent;
  color: #fff;
}

.dark-modal .modal-content {
  background-color: #181821 !important;
  color: #fff !important;
  border-color: #fff;
}
.modal-header .btn-close {
  display: none;
}
body .modal-header button {
  background: none;
  color: #000;
  border: none;
}
.dark-modal .modal-content button,
.theme-dark .info-popup button {
  color: #fff !important;
  fill: #fff;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  border: none;
}

.theme-dark,
.theme-dark .mobile-nav > nav {
  background-color: #181821 !important;
  color: #fff !important;
}
.theme-dark .mobile-nav > nav {
  background-color: #1f232d !important;
  background: #1f232d url('../public/img/backgrounds/mob-menu-bg-op.png');
  background-size: cover !important;
  background-repeat: no-repeat !important;
}


/* .theme-dark {
  background: url('../public/img/backgrounds/main-bg.jpg') no-repeat;
  background-size: 100%;
  background-position: 0 -253px;
} */

.theme-dark ul *,
.theme-dark span,
.theme-dark a,
.theme-dark p,
.theme-dark strong,
.bg-zone .light-color {
  color: #fff !important;
}

.theme-dark .btn-primary svg,
.theme-dark .btn-primary path {
  fill: #fff !important;
  color: #fff !important;
}


.flex-box {
  display: flex;
}

.flex-grow-1 > * {
  flex-grow: 1;
}

.align-end {
  align-items: end;
}
.align-flex-center {
  align-items: center;
}
.align-center,
.a-center {
  text-align: center;
}
.a-right {
  text-align: right;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  box-sizing: border-box;
}

.align-center {
  text-align: center;
}

.padding-30 {
  padding: 30px;
}

.accent-bg1 {
  background-color: #00c8fb;
  fill: #00c8fb;
  color: #fff;
}

body .accent-txt1 {
  color: #00c8fb !important;
  /* color: #4CE7E9 !important; the old one */ 
}

html body .sg-button {
  padding: 10px 34px 9px 34px;
  font-family: "Lato Black";
  font-size: 13px;
  border-radius: 10px;
}

.copied-popup,
.info-popup {
  position: absolute;
  z-index: 999999999;
  right: 25px;
  width: 100px;
  height: 27px;
  top: 6px;
  border-radius: 4px;
  border: 1px solid #babfc7;
  padding: 5px;
  display: none;
  opacity: 0.95;
  background-color: #fff;
}

.dark-color .copied-popup,
.dark-color .info-popup,
.theme-dark .info-popup {
  background-color: #222628;
}
.dark-color .copied-popup *,
.dark-color .info-popup {
  color: #fff !important;
}

.theme-light .info-popup {
  background-color: #fff !important;
  color: #1e2329 !important;
}

.copied-popup span {
  font-size: 10px;
  position: absolute;
    top: -8px;
    left: 3px;
}
.result-list .copied-popup {
  width: 105px;
}
.result-list .copied-popup span {
    text-align: center;
    position: static;
    display: block;
}
.info-popup { 
  height: auto;
  width: 200px;
  right: 0;
  padding-right: 18px;
  text-align: left;
}

.group-table-inner {
  /* display: inline-block; */
  position: relative;
  text-align: center;
}
.group-table .info-popup {
  width: 100%;
  top: 60px;
  padding-right: 0;
}

.mobile-table-info {
  margin-right: 15px;
  display: none !important;
}

.group-table-grid-tb {
  width: 100%;
  text-align: center;
  margin: auto;
}

.group-table-grid-tb th {
  border-left: 1px solid;
  padding: 0 8px;
  font-weight: 400;
}
.group-table-grid-tb th:first-child,
.group-table-grid-tb td:first-child {
  border: none;
}

.group-table-grid-tb td {
  padding: 0 8px;
  border-left: 1px solid #fff;
  font-weight: 600;
  font-size: 13px;
}

/* .group-table-grid > div > div {
  width: 600px !important;
} */

.info-popup.visible {
  display: block;
}

.info-popup .modal-dialog{
  max-width: 100%;
  padding: 0 10px;
}
/* .mobile-table-info {
  position: relative;
} */

.mobile-table-info .info-icon {
  margin: 0;
}

.mobile-table-info .info-popup {
  width: 80%;
  /* max-width: 500px; */
  text-align: left;
  padding: 10px;
  top: 40px;
  /* height: 150px; */
  /* overflow: auto; */
  left: 10%;
  height: calc(100vh - 80px);
  overflow: auto;
  position: fixed;
}
.mobile-table-info .info-item {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #68686e;
}
.info-popup .txt {
  text-wrap: wrap;
  font-weight: 400;
}

.close-btn {
  position: absolute;
  top: 3px;
  right: 7px;
}

/* End Utils */
/* Start Header & Main Navigation */

/* Sticky header */
.header-outer {
  position: relative;
}
.header-outer .app-header {
  position: fixed;
  z-index: 3;
  top: 0;
  width: 100%;
  background-color: #fff;
}

.theme-dark .header-outer .app-header {
  /* background-color: #181A20; */
  /* background-color: transparent; */
  background-color: #181821;
}

/* End Sticky header */
.app-header {
  min-height: 60px;
  padding: 10px 40px;
  /* box-shadow: 0 2px 5px rgba(186, 191, 199, 0.5);
  border-bottom: 1px solid #babfc7; */
  margin-bottom: 60px;
}
.calls-page .app-header {
  margin-bottom: 0;
}
.logo-outer {
  width: calc((100% - 1278px) / 2);
  position: relative;
  z-index: 999;
  min-width: 200px;
}
.logo-outer a {
  vertical-align: middle;
  display: inline-block;
  text-decoration: none;
}

.logo-outer img {
  vertical-align: middle;
  height: 24px;
}

.logo-outer span {
  margin-left: 20px;
  font-size: 13px;
  font-weight: 600;
}

.nav-outer {
  /* margin-left: auto; */
  width: 1278px;
  max-width: 100%;
  text-align: right;
}

.nav-outer .search-img {
  margin-right: 60px;
  height: 30px;
}

.search-img {
  display: none;
}

.theme-icon {
  width: 20px;
  height: 20px;
  margin-top: 2px;
  cursor: pointer;
  transition: all 0.5s;
}

#mode-dark,
#mode-light {
  transition: all 0.5s;
}

.toggle-btn-section {
  height: 20px;
}

.app-header .flex-box {
  align-items: baseline;
}

.main-nav ul {
  display: flex;
  gap: 20px;
}

.main-nav ul li {
  list-style: none;
  margin-right: 20px;
}

.main-nav ul li a {
  text-decoration: none;
  font-size: 15px;
  font-family: "Lato Bold";
  font-size: 14px;
  /* font-weight: 400; */
  transition: all 0.5s;
  font-weight: 600;
}
.main-nav ul li a:hover {
  opacity: 0.8;
  color: #00c8fb !important;
}

.app-header .burger-btn {
  position: absolute;
  right: 30px;
  top: 30px;
}

.theme-dark .burger-btn div {
  background: #00c8fb;
}

/* End Header & Main Navigation */
/* Start Main content */

.main-content {
  max-width: 1278px;
  box-sizing: content-box;
  margin: auto;
  padding: 140px 20px 60px 20px;
  position: relative;
  z-index: 1;
  flex: 1;
  width: 100%;
}

.calls-page .main-content {
  padding: 0 20px 60px 20px;
}


.token-details > h1,
.cryptoGroup-img,
.group-table-inner > h3 {
  text-align: center;
}

.token-d {
  gap: 50px;
}
.token-d > div {
  gap: 50px;
  width: 50%;
}

.td-chart {
  width: 50%;
}

.td-chart > div > div > div {
  background: rgb(160 246 243 / 10%);
  border-radius: 20px;
}

.cryptoGroup-desc p {
  width: 72%;
  margin: auto;
}

.right-padding-50 {
  padding-right: 50px;
}

.logo-image {
  text-align: center;
}

/* home page */

.sg-block.banner {
  margin-bottom: 140px;
}

.carousel-status {
  display: none;
}

.hp-carousel-item {
  align-items: center;
}

.home .control-arrow {
  display: none;
}

.hp-carousel-item .hp-carousel-left {
  text-align: left;
  width: 50%;
}

.hp-carousel-item .hp-carousel-left  h2 {
  margin-bottom: 35px;
  font-size: 32px;
}

.hp-carousel-item .hp-carousel-left  p {
  /* max-width: 420px; */
  margin-bottom: 56px;
  font-size: 15px;
}

.hp-carousel-item {
  padding: 0 1px;
}

.sg-trending-bock-img {
  position: relative;
  border-radius: 10px;
  border: 2px solid #00c8fb;
}

.sg-trending-bock-img img {
  border-radius: 10px;
}

.sg-trending-bock {
  padding: 20px;
  margin-bottom: 200px;
}

.trending-calls-btn {
  position: absolute;
  width: 100%;
  bottom: 37px;
  margin: 0px 19px;
}

.ab-box-r .trending-calls-btn {
  margin: 0px;
  bottom: 0;
}

/* .ab-box-r .sg-trending-bock-img {
  padding-bottom: 80px;
} */

.ab-box-r .sg-trending-bock-img .trending-calls-btn {
  bottom: -80px;
}

.sg-about-top {
  margin: 200px 0;
}

.sg-about-top h4 {
  margin: 25px 0;
}

.marq-inner {
  max-width: 1278px;
  margin: auto;
  display: flex;
  gap: 35px;
  padding: 8px 0;
  align-items: center;
  position: relative;
  /* border: 1px solid #00c4f7;
  border-left: 150px solid transparent;
  border-right: 150px solid transparent;
  border-top: 0; */
  width: 100%;
  box-sizing: content-box;
  overflow: hidden;
  /* border-bottom: 1px solid #00c4f7;
  border-image: linear-gradient(#00c4f7, #000000) 30;
  border-width: 1px;
  border-style: solid; */
}

.marq-inner:before {
  content: "";
  width: 86%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 1px;
  left: 7%;
  /* opacity: 0.6; */
  box-shadow: 0px 0px 0px #00c8fb;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#181a20+0,00c4f7+50,181a20+100&0+0,0+50,1+50,0+100 */
  background: linear-gradient(to right, rgba(24,26,32,0) 0%,rgba(0,196,247,1) 50%,rgba(24,26,32,0) 100%);
}

.marq-inner:after {
  content: "";
  width: 86%;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0px;
  left: 7%;
  opacity: 0.3;
  box-shadow: 0px 0px 0px #00c8fb;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#181a20+0,00c4f7+50,181a20+100&0+0,0+50,1+50,0+100 */
  background: linear-gradient(to right, rgba(24,26,32,0) 0%,rgba(0,196,247,1) 50%,rgba(24,26,32,0) 100%);
}

.marq-bg .marq-inner > h3 {
  font-family: 'Lato Bold';
  margin: 0;
  font-size: 13px;
}

.tr-tokens {
  background: #133b49;
  margin-bottom: 40px;
  top: 85px;
  position: absolute;
  z-index: 2;
  width: 100%;
  overflow: hidden;
}

.calls-page .tr-tokens {
  /* position: static; */
    position: absolute;
    top: 88px;
}

.adslider-outer {
  position: absolute;
  z-index: 2;
  top: 100px;
}

/* .ad-slider .marq-inner {
  max-width: 100%;
} */

.ad-slider .tr-tokens {
  position: relative;
  top: 0;
}

.ad-slider .marq-slider-container > div > div > div {
  vertical-align: middle;
  margin-left: 20px;
}

.ad-slider .slider {
  gap: 10px;
}
.ad-slider .slide {
  border-radius: 10px;
  background-color: #181821;
  padding: 20px;
}
.ad-slider .slide > div,
.ad-slider .ad-slider-item {
  height: 100%;
}
.ad-slider .control-dots {
  display: none;
}
.ad-slider .ad-slider-item img {
  vertical-align: middle;
  border-radius: 10px;
}
.ad-slider-item a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.marq-bg {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#181821+0,133b49+50,181821+100&1+0,0+50,0+50,1+100 */
background: linear-gradient(to right,  rgba(24,24,33,1) 0%,rgba(19,59,73,0) 50%,rgba(24,24,33,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  margin: auto;
  width: 100%;
}

.sg-outline {
  border: 2px solid #00c8fb;
  border-radius: 12px;
}

.tr-tokens h3 {
  font-size: 14px;
  font-weight: 400;
}

.tr-token-item {
  gap: 5px;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
}

.tr-token-item span {
  font-size: 15px;
}

.st-up {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 20px solid #0ec981;
  margin-left: 10px;
  display: inline-block;
}

.st-down {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 20px solid #eb465c;
  margin-left: 10px;
  display: inline-block;
}

.sg-trending-bock .top-tokens {
  gap: 55px;
}

.top-tokens > div {
  padding: 25px 28px;
}

.no-head-grid .ag-root .ag-header {
  /* display: none; */
  background: transparent;
  border-color: transparent;
}
.sg-trending-bock .grid-no-border {
  width: 50%;
}

.trending-calls img {
  max-width: 100%;
  margin-top: 40px;
}

.trending-calls-btn {
  text-align: center;
  margin-bottom: 15px;
}

.sg-about-top {
  gap: 50px;
}

.sg-about-top > div {
  width: 50%;
}

.sg-about-items {
  display: grid;
  grid-gap: 35px;
  grid-template-columns: 1fr 1fr 1fr;
}

/* .sg-about-block {
  margin-bottom: 350px;
} */

.sg-about-item {
  background: rgba(24,26,32,0.8);
}

.sg-about-item-top img {
  border-radius: 12px 12px 0 0;
  max-height: 300px;
}
.sg-about-item-top {
  text-align: center;
}

.sg-about-item-bottom {
  padding: 0 30px 30px 30px;
}

.sg-about-item-bottom p {
  text-align: justify;
  min-height: 130px;
}

.ab-box {
  gap: 40px;
}

.ab-box > div {
  width: 50%;
}

.ab-box-list {
  list-style: none;
}

.ab-box-list li {
  position: relative;
}

.ab-box-list li::before {
  content: "";
  color: #00c8fb;
  display: block;
  position: absolute;
  left: -24px;
  top: 9px;
  padding: 6px;
  background: #00c8fb;
  box-shadow: 0px 0px 16px #00c8fb;
  border-radius: 100px;
  filter: blur(1px);
  width: 13px;
  height: 13px;
  line-height: 0px;
}

.ab-box-list li h3 {
  font-size: 13px;
}

/* end home page */

.marq-slider-container > div{
  padding-left: 290px;
}

/* Start AG Grid React styles */


.ag-body {
  padding-bottom: 20px;
}

.top-grids .ag-body-horizontal-scroll {
  display: none !important;
}

.ag-root.ag-layout-normal, 
.ag-root.ag-layout-auto-height {
  border-radius: 15px;
}

.ag-root .ag-header, 
.ag-root-wrapper {
  background-color: #1f232d;
}

.ag-root .ag-header {
  border-width: 2px;
  border-color: #00c8fb;
  
}

body .ag-root-wrapper {
  border: none;
  overflow: visible;
  border-radius: 15px;
  border: 2px solid #00c8fb;
}

.ag-root-wrapper-body.ag-layout-normal.ag-focus-managed {
  height: 100%;
}

body .ag-row {
  cursor: pointer;
  background-color: #181a20;
  border-color: transparent;
}
.ag-row-hover  { background-color: transparent !important; }

body .ag-row:nth-child(even) {
  background-color: #1f232d;
}

.ag-body .ag-cell {
  font-weight: 500;
}

.rounded-img img {
  border-radius: 100px;
}

.rounded-img .image-name img {
  margin-right: 10px;
}

.custom-cell .image-name.ellipsis {
  width: 100%;
}

/* .ag-row .ag-cell {
  display: flex;
  align-items: center;
} */

.ag-row .ag-cell {
  padding-top: 8px;
  padding-bottom: 10px;
}

body .ag-cell, body .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 8px;
  padding-right: 8px;
}

.call-gr .ag-cell .btn-primary {
  width: 70px !important;
  padding-left: 0px;
  padding-right: 0px;
}

body .ag-header-cell,
body .ag-header-group-cell {
  padding-left: 8px;
  padding-right: 8px;
}

.ag-center-cols-viewport {
  height: calc(100% + 20px) !important;
}

.not-found {
  text-align: center;
  margin-top: 50px;
}

/* .token-details {
  padding: 15px;
} */

.token-grids h3 {
  margin-bottom: 30px;
  text-align: center;
}

.topCallsGrid {
  margin-bottom: 60px;
}

.top-grids {
  margin: 40px 0 20px 0;
  display: flex;
  gap: 65px;
}

.top-grids .custom-cell img {
  border-radius: 80px;
  max-height: 38px;
}

body .top-grids .ag-root-wrapper {
  border: none;
  background: transparent;
}

body .top-grids .ag-root-wrapper .ag-row {
  background: transparent !important;
  border: none !important;
}

body .top-grids .ag-root-wrapper .ag-row .ag-cell {
  padding: 0 10px;
}

.top-grids > div {
  width: 50%;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#181a20+0,181a20+7,172129+12,14313d+21,0f5266+35,133a48+54,171e25+80,181a20+85,181a20+100 */
  background: linear-gradient(to bottom,  rgba(24,26,32,1) 0%,rgba(24,26,32,1) 7%,rgba(23,33,41,1) 12%,rgba(20,49,61,1) 21%,rgba(15,82,102,1) 35%,rgba(19,58,72,1) 54%,rgba(23,30,37,1) 80%,rgba(24,26,32,1) 85%,rgba(24,26,32,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  padding: 10px 10px 0 10px;
  border-radius: 20px;
  position: relative;
}

.top-grids > div::before {
  content: "";
  width: calc(100% + 4px);
  height: 99%;
  display: block;
  position: absolute;
  top: -2px;
  left: -2px;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00c8fb+0,153340+51,172028+100&0.74+0,0.08+100 */
background: linear-gradient(to bottom,  rgba(0,200,251,0.74) 0%,rgba(21,51,64,0.4) 51%,rgba(23,32,40,0.08) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  z-index: -1;
  border-radius: 20px 20px 0 0;
}

.top-grids > div > h2 {
  font-size: 21px;
  margin-bottom: 10px;
}

.i-icon .ag-cell-label-container{
  background: url('../public/img/i_icon.png') no-repeat;
  background-size: 12px;
  background-position: right center;
}

.buyHeader .ag-cell-label-container{
  background: url('../public/img/dollar-50.png') no-repeat;
  background-size: 16px;
  background-position: 35px center;
}
.caHeader .ag-cell-label-container{
  background: url('../public/img/search-50.png') no-repeat;
  background-size: 16px;
  background-position: 30px center;
}
.chartHeader .ag-cell-label-container{
  background: url('../public/img/chart-50.png') no-repeat;
  background-size: 16px;
  background-position: 40px center;
}

.mid-banner-out {
  visibility: hidden;
  height: 60px;
  display: flex;
  gap: 65px;
  margin-bottom: 20px;
}
.mid-banner-out > div {
  width: 50%;
}
.mid-banner {
  gap: 30px;
}

.mid-banner > div {
  width: 50%;
}

.mid-banner img,
.mid-banner video,
.video-out {
  max-width: 100%;
  border-radius: 20px;
  /* border: 2px solid #00c8fb; */
}
.mid-banner img:hover,
.mid-banner video:hover {
  opacity: 0.85;
}

.ag-root ::-webkit-scrollbar,
.mobile-table-info .info-popup ::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

.ag-root ::-webkit-scrollbar-track,
.mobile-table-info .info-popup ::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.theme-dark .ag-root ::-webkit-scrollbar-track,
.theme-dark .mobile-table-info .info-popup ::-webkit-scrollbar-track {
  background-color: #222628;
  border-radius: 100px;
}

.ag-root ::-webkit-scrollbar-thumb,
.mobile-table-info .info-popup ::-webkit-scrollbar-thumb {
  background-color: #00c8fb;
  border-radius: 100px;
}

body .ag-header,
.ag-header-cell,
.ag-header-cell-comp-wrapper,
.ag-cell-label-container  {
  overflow: visible !important;
}

.ag-header-cell,
.ag-header-cell * {
  pointer-events: all;
}

.ag-header-cell-text {
  cursor: default;
}

.ag-header-cell-comp-wrapper .ag-header-cell-text::after,
.info-icon {
  content: "i";
  font-size: 11px;
  font-style: italic;
  background-color: #fff;
  border-radius: 50px;
  display: inline-block;
  color: #181821 !important;
  margin-left: 10px;
  padding: 0px 6px 0 6px;
  text-align: center;
  display: none;
}

.ag-header-cell[col-id="number"] .ag-header-cell-comp-wrapper .ag-header-cell-text::after {
  display: none;
}

.theme-light h3 .info-icon {
  background-color: #babfc7;
  color: #fff !important;
}

.ag-center-cols-container {
  min-width: 1278px;
}

.callers-list-page .ag-center-cols-container {
  min-width: auto;
}

/* .info-icon {
  display: none;
} */
/* .ag-center-cols-container, 
.ag-center-cols-container > div {
  width: 100% !important;
} */

.theme-dark span.info-icon {
  color: #181821 !important;
}

.theme-light .ag-header-cell-comp-wrapper .ag-header-cell-text::after,
.theme-light .ag-header-cell-comp-wrapper .info-icon {
  background-color: #babfc7;
  color: #fff !important;
}

.ag-tooltip {
  max-width: 300px !important;
  word-break: break-all;
}

.custom-cell .ellipsis {
  width: 90%;
  margin-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

div[col-id="ImpactOfCall"],
div[col-id="FromCalltoPeak"],
.green-value-label {
  color: #0ECB81 !important;
}

.red-value-label {
  color: #F6465D !important;
}

/* End AG Grid React styles */

/* Start News page */

.news-list {
  width: calc(100% + 40px);
  margin-top: 40px;
}

.news-list-inner {
  gap: 40px;
  /* flex-flow: wrap; */
  flex-flow: row wrap;
}

.news-li {
  border-radius: 20px;
  flex-grow: 1;
  max-width: calc(33.33% - 40px);
  box-sizing: content-box;
  /* margin-left: 40px; */
}

.sg-card  {
  padding: 30px;
  position: relative;
  background: #181a20;
  border-radius: 20px;
  text-align: center;
  margin-top: 70px;
}

.sg-card-title {
  margin: 20px 0;
}

.sg-card-img img {
  max-width: 168px;
  border-radius: 180px;
}

.sg-card-title h3{
  font-size: 48px;
}

.sg-card-title h4{
  font-size: 13px;
}

.sg-card-exerpt {
  text-align: left;
}

.sg-card-socials {
  justify-content: center;
}

/* .news-li:first-child,
.news-li:nth-child(4) {
  margin-left: 0;
} */

.news-carousel-item {
  gap: 40px;
  padding: 0 2px;
  margin-bottom: 40px;
  align-self: stretch;
  padding-bottom: 40px;
}

.news-carousel-item .news-li {
  max-width: 100%;
  margin-left: 0;
}

.news-carousel-item .news-li-exerpt {
  display: none;
}
.news-slide-left {
  max-width: calc(66.67%);
  height: 100%;
  position: relative;
  background: #181A20;
  border-radius: 0 0 20px 20px;
}

.news-slide-left::before {
  content: "";
  width: calc(100% + 4px);
  height: 80%;
  display: block;
  position: absolute;
  bottom: -2px;
  left: -2px;
  background: linear-gradient(to bottom, rgba(23,32,40,0.08) 0%,rgba(21,51,64,0.4) 49%,rgba(0,200,251,0.74) 100%);
  z-index: -1;
  border-radius: 0 0 20px 20px;
}
.news-slide-left .news-li-details::before {
  background: none;
}

.news-slide-right {
  max-width: calc(33.33% - 40px);
}

.news-slide-right .news-li:first-child {
  margin-bottom: 40px;
}

/* .news-li:nth-child(3n) {
  page-break-after: always;
} */
.news-li-img,
.news-li-title {
  cursor: pointer;
}

.news-list .news-li-img  {
  height: 230px;
  background: #181A20;
  border-radius: 20px 20px 0 0;
}

.news-li-img img {
  border-radius: 20px 20px 0 0;
  width: 100%;
}

.news-li-title h3 {
  font-size: 19px;
  font-family: 'Lato Bold';
  height: 45px;
  overflow: hidden;
}

.news-carousel-item  .news-li-title h3 {
  text-align: left;
}

.news-slide-left .news-li-title h3 {
  font-size: 20px;
}


.news-li-details {
  padding: 30px;
  position: relative;
  background: #181a20;
  border-radius: 0 0 20px 20px;
}

.team-page-main > .row > div:nth-child(odd) {
  padding-left: 0;
  padding-right: 40px;
}
.team-page-main > .row > div:nth-child(even) {
  padding-right: 0;
  padding-left: 40px;
}

.news-li-exerpt {
  height: 65px;
  overflow: hidden;
}

.news-li-details p {
  font-family: "Lato Regular";
  font-size: 14px;
}

body #root .news-li-details .right span {
  font-family: "Lato Bold";
  font-size: 12px;
  color: #00c8fb !important;
}

.news-li-details::before,
.sg-card-details::before {
  content: "";
  width: calc(100% + 4px);
  height: 80%;
  display: block;
  position: absolute;
  bottom: -2px;
  left: -2px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#172028+0,153340+49,00c8fb+100&0.08+0,0.74+100 */
  background: linear-gradient(to bottom,  rgba(23,32,40,0.08) 0%,rgba(21,51,64,0.4) 49%,rgba(0,200,251,0.74) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  z-index: -1;
  border-radius: 0 0 20px 20px;
}

.news-li-btn {
  align-items: center;
}

.news-li-btn .btn {
  margin-top: 20px;
}

.load-more-news {
  margin-top: 100px;
  text-align: center;
}

.load-more-news button {
  padding-left: 100px;
  padding-right: 100px;
}

.news-slide-right img {
  max-height: 200px;
}

.news-slide-right .news-li-title h3 {
  margin-bottom: 0;
}

.news-slide-right .news-li-details {
  padding: 25px;
}


/* End News page */

.banner iframe {
  width: 100%;
  height: 90px;
}

.social-links {
  /* position: absolute;
  top: 50px; 
  right: 0;*/
  position: fixed;
  top: 220px;
  right: 70px;
}

.social-links ul {
  padding-left: 0;
}

.social-links ul li {
  list-style: none;
  margin-top: 12px;
  /* padding: 5px; */
  text-align: center;
  height: 36px;
  background: #fff;
  border-radius: 100px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-links ul li a {
  vertical-align: middle;
  display: flex;
  padding: 5px;
  background: #fff;
  border-radius: 100px
}

.social-links ul li img {
  width: 20px;
  vertical-align: middle;
}

.hr-div .social-links {
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
}

.hr-div .social-links ul {
  display: flex;
}

.hr-div .social-links ul li {
  margin-right: 12px;
}

.s-links ul {
  list-style: none;
  padding: 0;
}

.s-links ul li {
  margin-top: 10px;
}

.s-links ul li {
  font-size: 1em;
  margin-bottom: 40px;
  max-width: 540px;
  padding-right: 10px;
}
.img-wrapp {
  vertical-align: middle;
  display: flex;
  padding: 2px 8px;
  background: #fff;
  border-radius: 100px;
  position: absolute;
  left: 20px;
  top: 10px;
  width: 36px;
  height: 36px;
}
.s-links ul li img {
  width: 20px;
  vertical-align: middle;
}
.s-links ul li a {
  /* word-break: break-all; */
  text-decoration: none;
  display: block;
  font-size: 1em;
  font-weight: 500;
  position: relative;
  padding-left: 70px;
}
.s-links ul li a span {
  font-size: 0.7em;
  display: block;
  font-weight: 300;
}

.theme-dark .s-links ul li a span {
  color: #c5c5c5 !important;
}

.theme-dark .s-links ul li a:hover span {
  text-decoration: none;
  color: #fff !important;
}

/* Header Social Links */

.token-metrics {
  margin-bottom: 30px;
}

.token-metrics > div {
  margin-bottom: 20px;
}

/* End Main content */
.page-content {
  max-width: 1200px;
  margin: auto;
}

.ourVision * {
  font-family: 'Lato Regular';
}

.ourVision h1,
.ourVision h2 {
  font-family: 'Lato Bold';
}

.content-section {
  /* padding: 30px; */
  position: relative;
}

body .bg-up {
  padding: 30px 0;
}

.content-section:after {
  /* height: 3px; */
  display: block;
  content: "";
  width: 70%;
  margin: auto;
  /* background: transparent; */
  margin: 90px auto 0 auto;
  /* border-bottom: 1px solid #babfc7;
  box-shadow: 0 3px 5px rgba(186, 191, 199, 0.5); */
}
.content-section.full-width:after,
.content-section.no-border-line:after {
  display: none;
}
.content-list {
  padding-left: 10px;
}
.content-list li {
  list-style: none;
  margin-bottom: 5px;
  font-size: 1.25em;
}

.content-list li:before {
  content: '✓';
  margin-right: 10px;
  font-size: 1em;
  color: #00c8fb;
}

/* .theme-light .content-section:after {
  background: #1e2329;
} */

.page-content .content h1 {
  margin-bottom: 50px;
  font-size: 2.6em;
  margin-left: 20px;
}

.content-section h2 {
  font-size: 1.5em;
}

.content-section p {
  /* font-size: 1.25em; */
  margin: 40px 0;
}
.content-section strong {
  /* font-size: 1.25em; */
  font-weight: 700;
}
.parallax {
  padding: 50px;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  perspective: 3px;
}

.parallax p {
  padding-right: 50px;
}

body .parallax-1,
body .blue-bg-1 {
  background: url('../public/img/backgrounds/bg-3_1200x600.jpg') no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

/* .what-next-bg-1 {
  background: url('../public/img/backgrounds/bg-5.jpg') no-repeat;
  background-size: cover;
  box-sizing: border-box;
}

.what-next-bg-2 {
  background: url('../public/img/backgrounds/bg-6.jpg') no-repeat;
  background-size: cover;
  box-sizing: border-box;
  background-position: 100%;
}

body .what-next-bg-3 {
  background: url('../public/img/backgrounds/bg-4-2.jpg') no-repeat;
  background-size: cover;
  box-sizing: border-box;
} */

body .blue-bg-2 {
  background: url('../public/img/backgrounds/blue-bg-2.JPG') no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}

.align-right p,
.align-right h2 {
  text-align: right;
}

body .bg-video video {
  object-fit: cover;
  height: 100%;
  width: 100%;
  filter: blur(4px);
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
}

html body .parallax-1 *,
body .blue-bg-2 *,
body .blue-bg-1 * {
  color: #fff !important;
}

html body .parallax-2 * {
  color: #1e2329 !important;
}

.parallax-2 {
  height: 400px;
}

.parallax-inner {
  /* max-width: 1200px; */
  margin: auto;
  background-size: cover;
  position: relative;
}

.article-inner {
  max-width: 1200px;
  margin: auto;
}

.ag-unselectable {
  -webkit-user-select: text !important;
  user-select: initial !important;
}
div[col-id="BuyLink"] {
  cursor: default !important;
}

.parallax-2 .parallax-inner {
  background: url('../public/img/backgrounds/bg-2_1200x600.jpg') no-repeat;
  background-position: center -300px;
  transform: translateZ(-1px) scale(1.5);
  background-size: cover;
  /* Keep the image from overlapping sibling elements. */
  z-index: -1;
  height: 400px;
  left: -70px;
  width: calc(100% + 70px);
}

.parallax-static {
  max-width: 1200px;
  margin: auto;
  position: relative;
  top: 60%;
}


body .btn-primary {
  background: #00c8fb;
  border-color: #00c8fb;
}

body .btn-secondary {
  background: #babfc7;
  border-color: #babfc7;
}

body .btn-primary:hover {
  background: #00c8fb;
  border-color: #00c8fb;
  opacity: 0.8;
}

body .btn-primary:active,
body .btn-primary:focus-visible {
  background: #00c8fb !important;
  border-color: #00c8fb !important;
  opacity: 0.8;
}

.desktop-cust-btn {
  position: relative;
}

.desktop-cust-btn button{
  position: absolute;
  width: 152px;
  top: -75px;
  right: 0;
}

.aplly-more-list {
  list-style: none;
  padding: 0;
}

.aplly-more-list li {
  padding: 3px 15px;
  border-radius: 30px;
  border: 1px solid rgb(222, 226, 230);
  margin-right: 5px;
  margin-top: 15px;
  cursor: pointer;
  display: inline-block;
}

.aplly-more-list .primary {
  border-color: #00c8fb;
  color: #00c8fb;
}

.our-token-head {
  justify-content: flex-start;
  align-items: center;
  gap: 140px;
  margin-bottom: 190px;
}

.our-token-head img {
  max-width: 100%;
}

.our-token a.btn {
  padding: 9px 33px;
}

.our-token-head a.btn {
  padding: 9px 67px;
}

.our-token-head h1 a,
.our-token-head h1 small {
  display: block;
  font-size: 72px;
  text-decoration: none;
  font-family: 'Lato Black';
}

.our-token-head h1 small {
  font-size: 24x;
  font-family: 'Lato Bold';
}

.contr {
  padding: 30px;
  border: 1px solid #00c8fb;
  border-radius: 20px;
  display: block;
  margin: 30px 0;
  word-break: break-all;
}

.contr span {
  font-size: 13px;
  font-family: 'Lato Bold';
}

.our-token-welc {
  margin-bottom: 100px;
}

.tokenomics-out {
  margin-bottom: 200px;
}

.tokenomics {
  gap: 30px;
}

.tokenomics > div {
  width: 33.33%;
  padding: 20px;
  border: 1px solid #00c8fb;
  text-align: center;
  border-radius: 20px;
}

.tokenomics > div > h3, 
.tokenomics-out > h2 {
  font-size: 38px;
  font-family: 'Lato Black';
  text-align: center;
}

.tokenomics-out > h2 {
  margin-bottom: 40px;
}

.our-token-bottom > h2 {
  font-family: 'Lato Black';
  margin-bottom: 80px;
}

.our-token-bottom > .flex-box > div {
  width: 50%;
}

.our-token-bottom > .flex-box > div p {
  margin: 40px 0 0 0;
}

.team h2 {
  margin-bottom: 70px; 
  font-size: 28em;
}

.team h3,
.team h5 {
  margin-bottom: 30px; 
}

.team h3 {
  font-size: 1.5em;
}

.team-inner {
  display: flex;
  text-align: center;
  gap: 120px;
  flex-wrap: wrap;
}

.person-contact ul {
  list-style: none;
  margin-top: 40px;
  padding: 0;
}

.contact-link {
  position: relative;
  display: inline-block;
  padding-left: 33px;
  margin-bottom: 20px;
  text-decoration: none;
  font-weight: 500;
}

.contact-link .img-wrapp {
  left: 0;
  top: 0;
  vertical-align: middle;
  display: inline-block;
  width: 26px;
  height: 26px;
  padding: 0px 5px;
}

.contact-link .img-wrapp img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.terms-list li {
  font-size: 13px;
}
.terms-list > li {
  margin-bottom: 20px;
  font-size: 1.2em;
  font-family: 'Lato Bold';
}

.terms-list > li ol {
  margin: 15px 0;
}

.lower-alpha {
  list-style: lower-alpha;
}

.p-logo img {
  /* height: 80px; */
}
.p-contacts ul {
  padding: 0;
  margin-top: 30px;
  list-style: none;
}

.mobile-social {
  display: none;
}

.p-head {
  gap: 90px;
  align-items: center;
}

.p-section {
  margin-top: 90px;
}
.partners .p-section:first-child {
  margin-top: 0;
}

.partners .sg-card-socials {
  justify-content: left;
}

.partners .sg-card-socials .social-links ul li {
  margin-top: 40px;
}

.st-frame {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  border: 0;
  overflow: hidden;
}

.staking-iframe {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  height: 100vh; 
}

/* Start Roadmap page */

.rm-out {
  width: 100%;
}

.rm-item {
  padding: 50px;
  border: 2px solid #00c8fb;
  border-radius: 20px;
  box-shadow: 0px 0px 20px #00c8fb;
  margin: 0 0 200px 0;
  width: 50%;
  position: relative;
}

.rm-out:last-child .rm-item {
  margin-bottom: 0;
}

.rm-out:nth-child(even) .rm-item {
  margin-left: auto;
}
.rm-out .rm-item:after,
.rm-out .rm-item:before {
  content: "";
  background: #00c8fb;
  position: absolute;
  z-index: -1;
  box-shadow: 0px 0px 20px #00c8fb;
}
.rm-out:nth-child(1) .rm-item:after,
.rm-out:nth-child(2) .rm-item:after,
.rm-out:nth-child(3) .rm-item:after,
.rm-out:nth-child(4) .rm-item:after,
.rm-out:nth-child(5) .rm-item:after,
.rm-out:nth-child(6) .rm-item:after  {
  width: 2px;
  height: 305px;
}

.rm-out:nth-child(2) .rm-item:before,
.rm-out:nth-child(3) .rm-item:before,
.rm-out:nth-child(4) .rm-item:before,
.rm-out:nth-child(5) .rm-item:before,
.rm-out:nth-child(6) .rm-item:before,
.rm-out:nth-child(7) .rm-item:before  {
  width: 205px;
  height: 2px;
}

.rm-out:nth-child(even) .rm-item:before {
  left: -205px;
  top: 100px;
}

.rm-out:nth-child(3) .rm-item:before,
.rm-out:nth-child(5) .rm-item:before,
.rm-out:nth-child(7) .rm-item:before   {
  left: 100%;
  top: 100px;
  width: 203px;
}

.rm-out:nth-child(odd) .rm-item:after {
  right: 200px;
  bottom: -305px;
}

.rm-out:nth-child(2) .rm-item:after,
.rm-out:nth-child(4) .rm-item:after,
.rm-out:nth-child(6) .rm-item:after {
  right: calc(100% - 200px);
  bottom: -305px;
}

.rm-out:last-child .rm-item:after {
  display: none;
}

.rm-head {
  gap: 30px;
  margin-bottom: 50px;
}

.rm-out:nth-child(1) .rm-item .rm-head {
  align-items: center;
}

.rm-item .qtr {
  font-size: 72px;
  font-family: 'Lato Black';
  position: relative;
  line-height: 70px;
  padding-bottom: 35px;
}
.rm-item .qtr small {
  font-size: 13px;
  font-family: 'Lato Bold';
  position: absolute;
  bottom: 0;
  right: -20px;
}

.rm-item p span,
.rm-item .rm-head h2 {
  color: #00cbfe !important;
}

.rm-item .rm-head h2 {
  font-size: 32px;
  font-family: 'Lato Bold';
}

.rm-out:nth-child(4) .rm-item .rm-head div:first-child {
  width: 100%;
}

/* End Roadmap page */


/* Start footer */
.footer-bg {
  position: relative;
  /* margin-top: 350px; */
}
.footer-bg:before {
  width: 100%;
  height: 960px;
  position: absolute;
  top: -960px;
  content: "";
  display: block;
  /* background: url('../public/img/backgrounds/BG-1-1920x906.png') no-repeat; */
  background-size: 100% 100%;
}

.footer-outer {
  /* display: flex;
  justify-content: center; */
  background: #1f232e;
}

.app-footer {
  padding: 100px 0;
  width: 1278px;
  margin: auto;
  max-width: 100%;
}

.footer-col {
  flex: 1;
}

.footer-col .logo-outer {
  min-width: 260px;
}

.footer-col .logo-outer span{
  font-size: 28px;
  top: 8px;
  position: relative;
}

ul.footerLinks {
  gap: 20px;
  padding: 0;
  padding: 15px 0 0 0;
}

ul.footerLinks li {
  list-style: none;
  margin-bottom: 20px;
}

.app-footer a {
  text-decoration: none;
  font-size: 15px;
}

.app-footer a:hover {
  opacity: 0.8;
}

.app-footer .logo-outer {
    position: static;
}

.partners-page .p-head {
  text-align: justify;
  align-items: flex-start;
}

.p-logo {
  margin-top: 5px;
  min-width: 290px;
}

.partners-link > span {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.partners-link .sm-txt {
  width: 120px;
  font-size: 8px;
}
.partners-link > .sm-txt span {
  font-size: 15px;
  line-height: 15px;
}

.copy-right {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 0;
}

.f-p-content {
  position: relative;
  margin-bottom: 90px;
}

.f-p-inner {
  padding: 100px 10%;
  border: 2px solid #00c8fb;
  border-radius: 20px;
}
.close-f-p::after {
  content: ">";
  font-size: 30px;
  font-weight: bold;
  transform: rotate(90deg);
  position: absolute;
  top: 45px;
  right: 45px;
  font-family: monospace;
  cursor: pointer;
}

.footer-page h2 {
  margin-bottom: 40px;
}

.footer-page .rm-main h3 {
  font-size: 13px;
  font-family: 'Lato Bold';
}

.footer-page .rm-main p span,
.theme-dark ul .blue-link,
body #root .blue-link,
div[col-id="TokenName"] > div,
div[col-id="FullTokenName"] > div,
div[col-id="CallsFullTokenName"] > div {
  color: #00cbfe !important;
}

div[col-id="TokenName"] > div:hover, 
div[col-id="FullTokenName"] > div:hover,
div[col-id="CallsFullTokenName"] > div:hover {
  opacity: 0.8;
 }

.footer-page .flex-box {
  gap: 50px;
}

.footer-link {
  cursor: pointer;
}

.footer-link:hover {
  opacity: 0.8;
}

.footer-page .social-links ul li img {
  width: 50px;
}

.footer-page .social-links ul li {
  height: 50px;
  width: 50px;
}

.footer-page .social-links ul {
  gap: 11%;
  justify-content: center;
  margin-bottom: 80px;
}

.mails {
  justify-content: center;
  align-items: center;
}

.mails span a {
  background: url('../public/img/mail-icon.png') no-repeat;
  height: 31px;
  display: block;
  /* left: 0; */
  /* position: relative; */
  padding-left: 57px;
  text-align: left;
  min-width: 270px;
}
.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
  transition: opacity 1s ease-out;
}
/* End footer */

.search-bar {
  /* width: 900px; */
  /* width: calc(100% - 280px); */
  width: 400px;
  margin: auto;
  min-width: 230px;
  max-width: 83%;
}

.modal-body .search-bar {
  margin-top: 5px;
}

.search-bar input {
  width: 100%;
  background: transparent;
  color: #fff;
  border-radius: 10px;
  border: 1px solid #00c8fb;
  outline: none;
  padding: 4px 15px;
  font-size: 13px;
  line-height: 26px;
}

.modal-body .search-bar input {
  right: 25px;
  position: relative;
}

.modal-body .result-list {
  align-items: center;
  padding: 15px 0;
  border: 1px solid #00c8fb;
  border-radius: 10px;
}
.modal-body .result-list li {
  align-items: center;
}
.modal-body .result-list li h3 {
  font-size: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5px;
}
.modal-body .result-list li h3 span {
  font-size: 13px;
  font-family: 'Lato Regular';
}

.modal-body .result-list img {
  height: 24px;
  margin-left: auto;
}

.mobile-app .app-header .search-bar input {
  display: none;
}

.full-screen-modal,
.full-screen-modal > div {
  width: 100VW;
  height: 100VH;
  max-width: 100%;
 }
 .full-screen-modal > div {
  display: block;
  left: 0;
  top: 0;
  margin: 0;
 }
 .full-screen-modal .modal-content {
  height: 100%;
  border: 0;
 }
 .full-screen-modal .modal-body {
  background: #0B0F13;
  width: 100%;
  box-sizing: content-box;
 }
 .left-close {
  right: 35px;
  position: absolute;
  top: 20px;
 }

 /* Left side navigation */
 .left-side-nav {
  position: fixed;
  left: 0;
  width: 70px;
  height: 100VH;
  z-index: 99;
  /* top: 80px; */
  top: 0;
  overflow: hidden;
  display: flex;
  flex: 0;
  transition: 0.7s cubic-bezier(.36, -0.01, 0, .77);
  /* background: #16242E; */
  background: #181821;
 }

 .expand-left-nav {
  width: 220px;
  flex: 1;
 }

 .left-side-nav .main-nav ul li a {
  color: #fff;
  display: block;
  margin-top: 25px;
  background: url('../public/img/social/header-social/Discord_20x20.svg') no-repeat;
  background-size: 23px !important;
  background-position: 0 0;
  padding-left: 40px;
  min-height: 23px;
 }

 .left-side-nav:not(.expand-left-nav) .main-nav ul li a span{
  display: none;
 }

 .left-side-nav .main-nav ul {
  display: block;
  padding-left: 20px;
 }

 .left-side-nav .main-nav nav {
  width: 220px;
 }

 .left-side-nav .main-nav ul li {
    /* margin-top: 25px;
    background: url('../public/img/social/header-social/Discord_20x20.svg') no-repeat;
    background-size: 23px !important;
    background-position: 0 0;
    padding-left: 40px;
    min-height: 23px; */
    max-height: 23px;
 }

 .left-side-nav.expand-left-nav .main-nav ul li {
  max-height: none;
}

.left-side-nav .main-nav nav > ul li.desktop:nth-child(1) {
  min-height: 38px;
}

.left-side-nav .main-nav nav > ul li.desktop:nth-child(1) a {
  background: url('../public/img/logo_58x59_2.png') no-repeat;
  background-size: 38px !important;
  min-height: 38px;
  left: -7px;
  position: relative;
  padding-left: 47px;
  line-height: 38px;
  font-size: 16px;
}

 .left-side-nav .main-nav nav > ul li.menu-calls > a {
    /* background: url('../public/img/social/header-social/Telegram_20x20.svg') no-repeat; */
    background: url('../public/img/menu/chart.png') no-repeat;
 }

.left-side-nav .main-nav nav > ul li.menu-news > a {
  background: url('../public/img/menu/news.png') no-repeat;
}
.left-side-nav .main-nav nav > ul li.menu-ourtoken > a {
  background: url('../public/img/menu/diamond.png') no-repeat;
}
.left-side-nav .main-nav nav > ul li.menu-TelegramApp > a {
  background: url('../public/img/menu/m-phone.png') no-repeat;
}

.left-side-nav .main-nav nav > ul li.menu-TelegramBots > a {
  background: url('../public/img/menu/bot.png') no-repeat;
}
.left-side-nav .main-nav nav > ul li.menu-about > a {
  background: url('../public/img/menu/info.png') no-repeat;
}

.expand-left-nav .main-nav nav ul li.menu-staking > a {
  background: url('../public/img/menu/tractor.png') no-repeat !important;
  background-size: 20px !important;
}

.expand-left-nav .main-nav nav ul li.menu-team > a {
  background: url('../public/img/menu/grup.png') no-repeat !important;
  background-size: 20px !important;
}

.expand-left-nav .main-nav nav ul li.menu-roadmap > a {
  background: url('../public/img/menu/road-map.png') no-repeat !important;
  background-size: 20px !important;
}

.expand-left-nav .main-nav nav ul li.menu-partners > a {
  background: url('../public/img/menu/partner.png') no-repeat !important;
  background-size: 20px !important;
}
.expand-left-nav .main-nav nav ul li.menu-ShillDetectBot > a {
  background: url('../public/img/menu/bot.png') no-repeat !important;
  background-size: 20px !important;
}
.expand-left-nav .main-nav nav ul li.menu-ShillNewsBot > a {
  background: url('../public/img/menu/bot.png') no-repeat !important;
  background-size: 20px !important;
}

.left-side-nav .main-nav nav > ul li ul {
  padding-left: 0;
}

.left-side-nav .main-nav ul li ul li a {
  background: none !important;
  padding-left: 26px;
  left: 30px;
  margin-top: 7px;
  position: relative;
}
/* .left-side-nav .main-nav ul li ul li:before {
  content: '-';
  text-indent: -5px;
} */

.left-side-nav .main-nav ul li ul li a span {
  font-size: 13px;
}

 .left-side-nav .burger-btn > div {
    background: #00c8fb;
    margin-left: 20px;
    width: 23px;
    height: 3px
 }

 .left-side-nav .kLTlmV {
  width: auto;
  height: 1rem;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: relative;
  z-index: 999;
}

.search-results {
  margin-top: 15px;
}
.app-header .search-results {
  margin-top: 0;
}

.search-results li:hover {
  opacity: 0.75;
  background: rgba(0, 200, 251, 0.1);
}

.result-list {
  list-style: none;
  padding: 0;
  right: 25px;
  position: relative;
}

.callers-top-info .result-list-out {
  gap: 20px;
}

.callers-top-info .result-list {
  right: 0;
}

.callers-top-info .result-list li {
  cursor: default;
  gap: 10px;
}
.callers-top-info .result-list li > span {
  display: block;
  min-width: 120px;
  color: #c5c5c5 !important;
}

.theme-dark .tkn-pr-usd {
  color: #0ECB81 !important;
}

.result-list-out .sg-outline {
  margin-bottom: 30px;
  padding: 20px;
}

.result-list-out .btn-primary {
  max-height: 35px;
}

.result-list-out .soc {
  align-items: center;
}

.callers-top-info .result-list-out .chart-out {
  height: calc(100% - 30px);
  padding: 0;
}
.callers-top-info .result-list-out iframe {
  width: 100%;
  height: 100%;
  /* border: 2px solid #00c8fb;
  border-radius: 12px; */
}

.result-list li {
  cursor: pointer;
  display: flex;
  padding: 3px 15px;
  border-radius: 10px;
}

.result-list li p {
  /* margin-left: auto; */
  text-overflow: ellipsis;
  overflow: hidden;
}

.callers-top-info li p {
  margin-bottom: 0px;
}

.callers-top-info h1 {
  line-height: 38px;
  text-align: center;
}
.callers-top-info h1 small {
  display: block;
  font-size: 13px;
  font-weight: 300;
  font-family: 'Lato Regular';
}

.callers-stat {
  border-radius: 10px;
  border: 2px solid #00c8fb;
  background: #181821;
  padding: 20px;
}
.callers-stat h3 {
  text-align: center;
}

.callers-stat-table tr {
  opacity: 0.75;
}
.callers-stat-table tr:hover {
  opacity: 1;
  /* background: rgba(0, 200, 251, 0.1); */
}

.callers-stat-table tr td {
  padding: 20px 10px;
  border-bottom: 1px solid #00c8fb;
  cursor: pointer;
}

.callers-stat-table tr:last-child td {
  border-bottom: none;
}

.telegramBots-page .content-section strong {
  color: #00c8fb !important;
}
.coming-soon-page {
  text-align: center;
}
.coming-soon-page img {
  max-width: 100%;
}

.td-img {
  width: 60px;
}
.call-group-img img {
  max-height: 60px;
  border-radius: 60px;
}

body .carousel .slide img {
  max-height: 400px;
}

.result-list-out > div {
  width: 50%;
}

.tkn-top-info {
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}
.tkn-top-info > div:first-child {
  max-width: 64px;
}
.tkn-top-info > div:first-child img {
  max-width: 100%;
}
.tkn-buttons .btn {
  width: 80%;
}

.bot-images,
.bot-pictures {
  gap: 40px;
  justify-content: center;
}
.bot-images > div {
  max-width: 240px;
}

.bot-images figure {
  display: flex;
  align-items: center;
  gap: 20px;
}

.bot-images img {
  max-width: 100%;
  border: 1px solid #00c8fb;
  border-radius: 10px;
  margin-top: 25px;
}
.bot-picture-out {
  gap: 20px;
  align-items: center;
}
.bot-picture img {
  max-width: 100%;
  /* border: 1px solid #00c8fb; */
  border-radius: 150px;
  margin-top: 25px;
}
.bot-images h3 {
  font-size: 28px;
  width: 205px;
}
.bot-images figcaption {
  margin-top: 20px;
}
.bot-images a {
  text-decoration: none;
}
.bot-images a:hover {
  opacity: 0.8;
}
.bot-picture {
  max-width: 240px;
}

/* Start Mobile Version */

@media (max-width: 320px) {
  .logo-outer span {
    display: none;
  }
}

@media (max-width: 900px) {
  .main-content,
  .app-footer {
    max-width: 100%;
  }
  .ab-box-r .sg-trending-bock-img .trending-calls-btn {
      bottom: 20px;
  }
  .desktop {
    display: none;
  }
  .mob-900 {
    display: block;
  }
  .nav-outer {
    margin-left: auto;
  }

  .main-nav > nav {
    padding-top: 15px;
  }

  .app-header .flex-box {
    align-items: center;
  }

  .search-img {
    display: inline;
  }

  .open-search {
    display: none;
  }

  .logo-outer img {
    height: 30px;
  }

  .logo-outer span {
      font-size: 13px;
      margin-left: 10px;
  }

  .app-header .burger-btn {
      top: 28px;
      height: 18px;
  }

  .app-header .social-links {
   display: none;
  }

  .mobile-nav .social-links {
    display: block;
   }
   .mobile-nav .social-links ul {
    margin: 0 0 20px 0;
   }

  .desktop-nav {
    display: none;
  }
  .main-nav ul {
    display: block;
    padding: 0;
  }
  .main-nav ul li {
    margin-top: 20px;
  }
  .main-nav ul li a {
    font-size: 13px;
  }
  .app-header,
  .main-out {
    padding: 20px;
  }

  body .main-content {
    padding: 80px 0 60px 0px;
  }

  

  .hp-carousel-item,
  .sg-about-top {
    flex-direction: column-reverse;
  }

  .sg-about-top {
    margin: 100px 0;
  }

  .sg-about-top h2.sg-h2 {
    margin-bottom: 25px;
  }

  .sg-about-top h4 {
    text-align: left;
  }
  .home .sg-about-top p {
    text-align: justify;
  }
  /* .home .carousel_3d_out {
    width: calc(100% + 160px);
    left: -80px;
    position: relative;
  } */
  .home .carousel_3d {
    margin: 30px auto;
    height: calc(100vh);
    position: relative;
    
  }

  .home .carousel_3d > div > div {
    width: 78%;
  }

  .home .carousel_3d h3 {
    font-size: 24px;
  }

  .home .carousel_3d .sg-about-item-bottom p {
    font-size: 13px;
    min-height: auto;
  }

  .home .carousel_3d .btn.btn-primary {
    margin-top: 10px;
  }

  .home .carousel_3d > div:last-child {
    /* carousel buttons */
    margin-left: calc(50% - 60px);
  }

  .sg-about-bottom {
    display: none;
  }

  .hp-carousel-item .hp-carousel-left,
   .sg-about-top > div { 
    width: 100%;
    text-align: center;
  }
  .hp-carousel-item .hp-carousel-left h2 {
    text-align: center;
}
  .hp-carousel-item .hp-carousel-left p {
    max-width: 100%;
    margin-bottom: 30px;
    text-align: justify;
  }

  .home .carousel.carousel-slider {
    padding-bottom: 40px;
  }

  .sg-block.banner {
   margin-bottom: 50px;
  }
  
  .trending-calls-btn {
      margin: 0px
  }

  .ab-box {
    flex-direction: column-reverse;
  }

  .ab-box > div {
    width: 100%;
  }
  .ab-box-r {
    text-align: center;
  }
  .about article > h2,
  .ab-box-l h2  {
    text-align: center;
  }

  .about article p {
    text-align: justify;
  }

  .footer-outer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-lists {
    flex-direction: column;
    align-items: center;
  }

  .marq-slider-container {
    max-width: calc(100% - 100px);
  }

  .ag-header-cell-comp-wrapper .ag-header-cell-text::after {
    display: none;
  }
  .group-table-inner {
    display: block;
  }

  .group-table-grid-tb th {
    font-size: 13px;
  }
  
  .group-table-grid-tb td {
    font-size: 13px;
  }

  .team-inner {
    display: block;
    text-align: center;
  }

  .person {
    margin-bottom: 80px;
  }

  /* .tr-tokens {
    margin-top: 20px;
  } */

  .marq-inner {
    padding: 15px;
    max-width: calc(100% - 65px);
}

.result-list-out {
  display: block;
}

.result-list-out > div {
  width: 100%;
}

.callers-top-info .result-list-out .chart-out {
  height: 350px;
}
 
 
  .top-grids:not(.top5t, .top5c, .top5v) {
    height: 45px;
    margin: 40px 0 20px 0;
    gap: 20px;
  }

  #root .topGrids-hidden-top-ads {
    margin: 115px 0 20px 0;
  }

  .mid-banner-out {
    gap: 20px;
    margin-bottom: 20px;
  }

  .top-grids.top5t,
  .top-grids.top5c,
  .top-grids.top5v {
    width: 100%;
    display: block;
  }

  .calls-page .main-content {
    padding: 0 20px 60px 0;
    margin-top: 50px;
  }

  .top-grids.top5t .top5t,
  .top-grids.top5c .top5c,
  .top-grids.top5v .top5v {
    width: 100%;
    display: block;
  }

  .top-grids.top5t .top5c,
  .top-grids.top5t .top5v,
  .top-grids.top5c .top5t,
  .top-grids.top5c .top5v,
  .top-grids.top5v .top5t,
  .top-grids.top5v .top5c {
    display: none;
  }

  .top-grids:not(.top5t, .top5c, .top5v) > div > h2 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .top-grids > div > h2 {
    text-align: center;
  }

.top-grids:not(.top5t, .top5c, .top5v) > div > h2::after {
  /* content: ">"; */
  position: absolute;
  transform: rotate(90deg);
  bottom: 0;
  left: calc(50% - 8px);
  font-family: monospace;
  font-weight: 700;
  font-size: 13px;
}

.top-grids:not(.top5t, .top5c, .top5v) > div {
  padding: 10px 30px 0 30px;
  border-radius: 10px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#181a20+0,143542+100 */
  background: linear-gradient(to bottom,  rgba(24,26,32,1) 0%,rgba(20,53,66,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.top-grids:not(.top5t, .top5c, .top5v) > div::before {
  border-radius: 10px;
}

.top-grids:not(.top5t, .top5c, .top5v) .calls {
  display: none;
}

  .top-grids .grid {
    overflow-x: hidden !important;
  }

  .top-grids .ag-center-cols-container {
    min-width: auto;
}

  .mid-banner img,
  .mid-banner video,
  .video-out  {
      border-radius: 8px;
  }
  .mid-banner {
    gap: 15px;
  }


  .call-gr {
    margin-bottom: 30px;
  }

  .call-gr .ag-body-viewport {
    width: calc(100% + 25px) !important;
  }

  .call-gr .ag-theme-alpine-dark {
    height: 730px !important;
  }

  .cutomize-mob {
    text-align: center;
  }

  ul.footerLinks {
    justify-content: center;
  }
  
  .app-footer {
    text-align: center;
  }

  .news-list {
    width: 100%;
  }
  .news-list-inner {
    display: block;
  }

  body .carousel .slide > div {
      width: calc(100% - 4px);
      position: relative;
      left: 2px
  }

  .slider .news-li-exerpt {
    display: none;
  }

  body .carousel .control-dots {
    bottom: -15px;
  }

  .news-li {
    width: 100%;
    max-width: 100%;
    margin-bottom: 40px;
  }

  .news-list .news-li {
    display: flex;
    gap: 10px;
    background: #181A20;
    border-radius: 20px;
  }

   .news-list .news-li-img {
    width: 40%;
    height: auto;
  }

  .news-list .news-li-details {
    width: 60%;
    padding: 10px;
    padding-left: 0;
    border-radius: 0 20px 20px 0;
  }

  .news-list .news-li-img img {
    border-radius: 20px 0 0 20px;
  }

  .news-list .news-li-details::before {
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#172028+0,153340+49,00c8fb+100&0.08+0,0.74+100 */
    background: linear-gradient(to right,  rgba(23,32,40,0.08) 0%,rgba(21,51,64,0.4) 49%,rgba(0,200,251,0.74) 100%);
    border-radius: 0 20px 20px 0;
  }

  .news-list .news-li-title h3 {
    font-size: 13px;
    height: 80px;
    margin-bottom: 0;
  }

  .news-list .news-li-details p {
    display: none;
  }

  .news-list .news-li-exerpt {
    height: 0;
  }

  .news-list .news-li-btn .right {
    margin-left: 0;
  }

  .news-list .news-li-btn .btn {
    display: none;
  }

  .rm-item {
    padding: 25px;
    border: 2px solid #00c8fb;
    border-radius: 20px;
    box-shadow: 0px 0px 20px #00c8fb;
    margin: 0 0 50px 0;
    width: 100%;
  }
  .rm-out:nth-child(odd) .rm-item:after,
  .rm-out:nth-child(even) .rm-item:after {
    right: 50%;
    bottom: -52px;
    height: 52px;
  }
  .rm-out .rm-item:before {
    display: none;
  }

  .rm-item .qtr {
    font-size: 38px;
  }
  .rm-item .qtr small {
    font-size: 12px;
  }
  .rm-item .rm-head h2 {
    font-size: 13px;
  }
  .rm-out:nth-child(4) .rm-item .rm-head div:first-child {
    width: auto;
  }  
  .rm-out .rm-item .rm-head {
    align-items: center;
  }

  .our-token-head {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }

  .our-token-head {
    gap: 20px;
  }

  .our-token-head > div {
    width: 100%;
    text-align: center;
  }

  .contr span {
    display: block;
  } 

  .our-token-welc {
    text-align: center;
  }

  .our-token-welc p {
    text-align: justify;
  }
  
  .tokenomics,
  .p-head {
    flex-direction: column;
  }
  
  .p-head {
    gap: 30px;
  }

  .p-logo {
    margin-top: 5px;
    text-align: center;
    width: 100%;
  }

  .p-logo .sg-card-socials {
    display: none;
  }

  .mobile-social {
    display: flex;
    justify-content: center !important;
  }

  .tokenomics > div {
    width: 100%;
    text-align: justify;
  }

  .tokenomics > div:last-child {
    text-align: center;
  }

  .our-token-bottom > h2,
  .our-token-bottom > .flex-box > div  h4 {
    text-align: center;
  }

  .our-token-bottom .flex-box {
    flex-direction: column;
    gap: 20px;
  }

  .our-token-bottom > .flex-box > div {
    width: 100%;
    margin-bottom: 0;
    text-align: justify;
  }

  .content-section p {
    margin: 20px 0;
  }

  .our-token-bottom > .flex-box > div p {
    margin: 20px 0 0 0;
  }

  .st-frame {
    width: calc(100% + 17px);
  }

  .token-d, 
  .token-d > div {
    flex-direction: column;
    width: 100%;
  }

  .token-d h1 {
    text-align: center;
  }

  .token-d .chart-el {
    flex-direction: column-reverse;
    text-align: center;
    margin-bottom: 30px;
  }
  .td-chart,
  .td-chart > div,
  .td-chart > div > div,
  .td-chart > div svg {
    width: 100%;
}
  .token-d .chart-el h3 {
    margin-bottom: 20px;
  }
  .token-d .chart-el table tr td{
    text-align: left;
  }

  .td-chart {
    border: 2px solid #00c8fb;
    border-radius: 20px;
  }

  .social-links ul {
    justify-content: center;
    margin-top: 40px;
  }

  .footer-page .social-links ul {
    flex-wrap: wrap;
  }


  .footer-page-box {
    flex-direction: column;
  }

  .footer-page-box p {
    text-align: justify;
  }

  .close-f-p::after {
    top: 30px;
  }

  .f-p-inner {
    padding: 40px 20px;
  }
  .footer-page .social-links ul {
    gap: 20px;
  }

  .mails {
    flex-direction: column;
  }

  .result-list-out > div {
    width: 100%;
  }

  #chart-out, 
  .bot-pictures > div {
    margin-bottom: 20px;
  }
  .bot-pictures {
    display: block;
    text-align: center;
  }
}

@media (max-width: 992px) { 
  .team-page-main > .row > div:nth-child(odd),
  .team-page-main > .row > div:nth-child(even) {
    padding: 0;
  }
}


@media (max-width: 420px) { 

  .group-table-grid-tb th {
    font-size: 13px;
  }
  
  .group-table-grid-tb td {
    font-size: 13px;
  }
}

@media (max-width: 600px) { 
  .hr-div .social-links {
    right: -15px;
  }
  .img-wrapp {
    padding: 3px 8px;
  }
}




@media (max-width: 1200px) {
  .app-header, .main-out,
  .calls-page .header-outer .app-header {
    padding: 20px;
  }
  .main-content {
    padding: 140px 0px 60px 0px;
  }
  .cryptoGroup-desc p {
    width: 75%;
  }
  body .bg-up {
    padding: 30px;
  }
}

@media (max-width: 640px) { 
  .cryptoGroup-desc p {
    width: 84%;
  }
  .slider .slide .news-li-img {
    max-height: 225px;
  }
}

@media (max-width: 480px) { 
  .cryptoGroup-desc p {
    width: 90%;
  }

}

@media (min-width: 1200px) and (max-width: 1320px) {
  .news-slider {
    width: calc(100% - 40px);
  }
  .news-list {
    width: 100%;
  }
}


@media (max-width: 1599px) {
  .social-links {
    right: 12px;
  }
}

@media (min-width: 901px) and (max-width: 1460px) {
  #root .main-content {
    padding-left: 90px; /* Desktop version only: Adjust the main-content according the new design with left aside menu and prevent elements overlapping */
    box-sizing: border-box;
  }
}

@media (min-width: 901px) {
  .desktop {
    display: block;
  }
  .mob-900,
  .logo-outer a,
  .mobile-nav {
    display: none;
  }

  .desktop-nav {
    display: block;
  }

  .ag-root-wrapper .info-icon {
    display: none;
  }
  .mobile-table-info {
    display: none !important;
  }
  .home .carousel_3d {
    display: none;
  }

  .hp-carousel-item > div:last-child {
    margin-left: auto;
  }
  
  /* .top-grids > div {
    pointer-events: none;
  } */

  .top-grids {
    margin: 160px 0 20px 0;
    display: flex;
    gap: 65px;
  }

  .group-table .modal-header {
    display: none;
  }
  .group-table .info-popup .modal-dialog {
    padding: 0;
  }
  .group-table-grid > div {
    max-width: 600px;
  }

  .group-table .info-popup {
    max-width: 600px;
    left: calc(50% - 300px);
  }

  .s-links ul li a {
    padding-left: 90px;
  }
  .person {
    width: calc(33.33333% - 80px);
  }

}

